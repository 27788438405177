import React, { useState, useEffect } from 'react';
import './App.css';
import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as Top } from './assets/top.svg';
import { ReactComponent as Bottom } from './assets/bottom.svg';

function DotMatrix({ number, color }) {
    const dotPatterns = {
        0: [
            [1, 1, 1, 1],
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 1, 1, 1]
        ],
        1: [
            [0, 1, 1, 0],
            [1, 1, 1, 0],
            [0, 1, 1, 0],
            [0, 1, 1, 0],
            [0, 1, 1, 0],
            [0, 1, 1, 0],
            [1, 1, 1, 1]
        ],
        2: [
            [1, 1, 1, 1],
            [0, 0, 0, 1],
            [0, 0, 0, 1],
            [1, 1, 1, 1],
            [1, 0, 0, 0],
            [1, 0, 0, 0],
            [1, 1, 1, 1]
        ],
        3: [
            [1, 1, 1, 1],
            [0, 0, 0, 1],
            [0, 0, 0, 1],
            [1, 1, 1, 1],
            [0, 0, 0, 1],
            [0, 0, 0, 1],
            [1, 1, 1, 1]
        ],
        4: [
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 1, 1, 1],
            [0, 0, 0, 1],
            [0, 0, 0, 1],
            [0, 0, 0, 1]
        ],
        5: [
            [1, 1, 1, 1],
            [1, 0, 0, 0],
            [1, 0, 0, 0],
            [1, 1, 1, 1],
            [0, 0, 0, 1],
            [0, 0, 0, 1],
            [1, 1, 1, 1]
        ],
        6: [
            [1, 1, 1, 1],
            [1, 0, 0, 0],
            [1, 0, 0, 0],
            [1, 1, 1, 1],
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 1, 1, 1]
        ],
        7: [
            [1, 1, 1, 1],
            [0, 0, 0, 1],
            [0, 0, 1, 0],
            [0, 1, 0, 0],
            [0, 1, 0, 0],
            [0, 1, 0, 0],
            [0, 1, 0, 0]
        ],
        8: [
            [1, 1, 1, 1],
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 1, 1, 1],
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 1, 1, 1]
        ],
        9: [
            [1, 1, 1, 1],
            [1, 0, 0, 1],
            [1, 0, 0, 1],
            [1, 1, 1, 1],
            [0, 0, 0, 1],
            [0, 0, 0, 1],
            [1, 1, 1, 1]
        ]
    };

    return (
        <div className="grid grid-rows-7 grid-cols-4 gap-0.5 p-1 bg-black rounded-md">
            {dotPatterns[number].map((row, rowIndex) =>
                row.map((dot, colIndex) => (
                    <div
                        key={`${rowIndex}-${colIndex}`}
                        className={`w-3 h-3 md:w-2 md:h-2 rounded-full ${
                            dot ? color : 'bg-gray-700'
                        }`}
                    />
                ))
            )}
        </div>
    );
}

function App() {
    // Function to calculate time left until target date
    const calculateTimeLeft = () => {
        const targetDate = new Date('2024-11-12T18:00:00+09:00'); // KST is UTC+9
        const now = new Date();
        const difference = targetDate - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        } else {
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        return timeLeft;
    };

    // State to hold time left
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // Update the countdown every second
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-white text-black p-3">
            <Top className="h-[100%] w-[100%] lg:h-80 lg:w-80"/>
            <h2 className="mt-6 text-lg font-light">ChillLit PERFORMANCE VIDEO</h2>
            <h1 className="text-2xl font-bold mt-2">&lt;ODDBALL&gt;</h1>

            {/* YouTube video preview */}
            <div className="my-6">
                <iframe
                    width="1000"
                    height="300"
                    src="https://www.youtube.com/embed/wT4G__3oXs8?si=VZ81KQUa4CiLxhgf"
                    title="ODDBALL Performance Video"

                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full rounded-lg shadow-lg"
                ></iframe>
            </div>

            {/*<div className="bg-white p-4 rounded-2xl mt-8 w-full max-w-full md:max-w-xl border-2 border-gray-800">
                 Upper Section: Home, Timer, Visitor
                <div className="grid grid-cols-1 gap-4 text-center">
                     Timer Section
                    <div className="flex flex-col items-center">
                        <div className="flex flex-wrap justify-center bg-black px-3 py-2 rounded-lg md:px-5 md:py-3 md:space-x-1">
                             Days
                            <div className="flex space-x-1 border border-orange-400 p-1">
                                <DotMatrix number={Math.floor(timeLeft.days / 10)} color="bg-yellow-500"/>
                                <DotMatrix number={timeLeft.days % 10} color="bg-yellow-500"/>
                            </div>
                            <span className="text-xl md:text-2xl font-bold text-white">:</span>
                             Hours
                            <div className="flex space-x-1 border border-orange-400 p-1">
                                <DotMatrix number={Math.floor(timeLeft.hours / 10)} color="bg-yellow-500"/>
                                <DotMatrix number={timeLeft.hours % 10} color="bg-yellow-500"/>
                            </div>
                            <span className="text-xl md:text-2xl font-bold text-white">:</span>
                             Minutes
                            <div className="flex space-x-1 border border-orange-400 p-1">
                                <DotMatrix number={Math.floor(timeLeft.minutes / 10)} color="bg-yellow-500"/>
                                <DotMatrix number={timeLeft.minutes % 10} color="bg-yellow-500"/>
                            </div>
                            <span className="text-xl md:text-2xl font-bold text-white">:</span>
                             Seconds
                            <div className="flex space-x-1 border border-orange-400 p-1">
                                <DotMatrix number={Math.floor(timeLeft.seconds / 10)} color="bg-yellow-500"/>
                                <DotMatrix number={timeLeft.seconds % 10} color="bg-yellow-500"/>
                            </div>
                            <span className="text-xl md:text-2xl font-bold text-white">:</span>
                        </div>
                        <p className="font-extrabold mt-2 text-green-500">PERIOD 6</p>
                    </div>
                </div>
                 Lower Section
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 text-center">
                     Fouls - Home
                    <div className="flex flex-col items-center">
                        <p className="font-extrabold">FOULS</p>
                        <div className="flex space-x-2 mt-1">
                            <DotMatrix number={0} color="bg-green-500"/>
                            <DotMatrix number={3} color="bg-green-500"/>
                        </div>
                        <p className="font-extrabold mt-1">WON</p>
                    </div>
                     Game Info
                    <div className="flex flex-col items-center">
                        <p className="font-extrabold">PLAYER FOULS</p>
                        <div className="flex space-x-2 mt-1">
                            <DotMatrix number={0} color="bg-red-600"/>
                            <DotMatrix number={6} color="bg-red-600"/>
                        </div>
                        <p className="font-extrabold mt-1">GAME</p>
                    </div>
                     Fouls - Visitor
                    <div className="flex flex-col items-center">
                        <p className="font-extrabold">FOULS</p>
                        <div className="flex space-x-2 mt-1">
                            <DotMatrix number={0} color="bg-green-500"/>
                            <DotMatrix number={4} color="bg-green-500"/>
                        </div>
                        <p className="font-extrabold mt-1">WON</p>
                    </div>
                </div>
            </div>*/}
            <p className="whitespace-pre-wrap font-mono my-5">
                "Are we just a bunch of oddballs, living to show the world our love?<br/>
                What does it even mean to be an 'oddball'? What's the standard for<br/>
                normal and abnormal, anyway? In a world full of differences,<br/>
                maybe we’re a bit more unique, maybe we look like oddballs to<br/>
                others—but isn’t that okay? After all, we’re still young, right?<br/>
                It’s fine to make mistakes; we’re here to create something new.<br/>
                So what if we fall? We’ve got our youth and passion,<br/>
                and we won’t let them go to waste.<br/>
                Here’s to hoping we all stay a little oddball ourselves!"
            </p>

            <p className="text-lg mt-4">2024.11.12</p>
            <p className="text-lg">KST 18:00 RELEASE</p>
            <div className="mt-1">
                <Logo className="h-52 w-52"/>
            </div>
            <Bottom className="h-[100%] w-[100%] lg:h-80 lg:w-80" />
        </div>
    );
}

export default App;
